import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import MarkdownContent from "../components/markdown"
import { Row, Col, CardColumns, Card, Breadcrumb } from "react-bootstrap"
import Helmet from "react-helmet"
import GridImage from "../components/grid-image"
import CoverImage from "../components/cover-image"

class CategoryChildren extends Component {
  render() {
    const children = []
    this.props.children.forEach((x, index) => {
      if (x.node.frontmatter.images && x.node.frontmatter.images.length > 2) {
        children.push(
          <Col
            lg="3"
            className={"text-center mb-2"}
            key={"category-col-" + index}
          >
            <a className="d-block" href={x.node.fields.slug}>
              <CoverImage image={x.node.frontmatter.cover_photo} alt={x.node.frontmatter.title} />
              <div className="category-name-container">
                <strong className="category-name">
                  {x.node.frontmatter.title}
                </strong>
              </div>
            </a>
          </Col>
        )
      }
    })
    if (this.props.title.toLowerCase().indexOf("wedding") > -1) {
      children.push(
        <Col lg="3" className="text-center mb-2" key="wedding-col-x">
          <Link to="/gifting" className="d-block">
            <CoverImage image={this.props.weddingGiftingImage} alt="Wedding Gifting" />
            <div className="category-name-container">
              <strong className="category-name">Wedding Gifting</strong>
            </div>
          </Link>
        </Col>
      )
    }
    return <Row className={"align-items-center"}>{children}</Row>
  }
}

class ImageGrid extends Component {
  render() {
    const images = []
    if (this.props.images) {
      this.props.images.forEach((image, index) => {
        images.push(
          <Card className={"text-center mb-4 border-0"} key={index}>
            <GridImage image={image.image} alt={image.caption} />
          </Card>
        )
      })
      return <CardColumns>{images}</CardColumns>
    } else {
      return ""
    }
  }
}

export const SubcategoryPageTemplate = ({
  title,
  intro,
  siblings,
  images,
  parent,
  parent_slug,
}) => (
  <div className={"category-page py-5 pb-0 light-bg"}>
    <div className={"container-fluid"}>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href={parent_slug}>{parent}</Breadcrumb.Item>
        <Breadcrumb.Item active>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <h1 className={"text-center mb-5"}>{title}</h1>
      <MarkdownContent content={intro} />
      <ImageGrid images={images} />
    </div>
  </div>
)

export const CategoryPageTemplate = ({ title, intro, children, weddingGiftingImage }) => (
  <div className={"category-page py-5 light-bg"}>
    <div className="container-fluid">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item active>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <h1 className={"text-center mb-5"}>{title}</h1>
      <MarkdownContent content={intro} />
      <CategoryChildren title={title} children={children} weddingGiftingImage={weddingGiftingImage}></CategoryChildren>
    </div>
  </div>
)

SubcategoryPageTemplate.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.string,
  siblings: PropTypes.array,
  parent: PropTypes.string,
  images: PropTypes.array,
  parent_slug: PropTypes.string,
}

CategoryPageTemplate.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.string,
  children: PropTypes.array,
  weddingGiftingImage: PropTypes.object
}

const CategoryPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const children = data.children.edges
  const siblings = data.siblings.edges
  const weddingGiftingImage = data.weddingGiftingImage

  let pageTemplate = null

  if (children.length > 0) {
    pageTemplate = () => (
      <CategoryPageTemplate
        title={frontmatter.title}
        intro={frontmatter.intro}
        children={children}
        images={frontmatter.images}
        weddingGiftingImage={weddingGiftingImage}
      />
    )
  } else {
    pageTemplate = () => (
      <SubcategoryPageTemplate
        title={frontmatter.title}
        intro={frontmatter.intro}
        siblings={siblings}
        images={frontmatter.images}
        parent={frontmatter.parent}
        parent_slug={data.parentslug.edges[0].node.fields.slug}
      />
    )
  }

  return (
    <Layout>
      <Helmet>
        <html lang="en" />
        <title>{frontmatter.seoTitle}</title>

        <meta name="description" content={frontmatter.metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={frontmatter.seoTitle} />
        <meta property="og:description" content={frontmatter.metaDescription} />
        <meta property="og:image" content={"https://www.deliciaecakes.com/" + frontmatter.cover_photo} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:title"
          content={frontmatter.seoTitle}
        />
        <meta
          property="twitter:description"
          content={frontmatter.metaDescription}
        />
        <meta property="twitter:image" content={"https://www.deliciaecakes.com/" + frontmatter.cover_photo} />
      </Helmet>
      {pageTemplate()}
    </Layout>
  )
}

CategoryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    children: PropTypes.shape({
      edges: PropTypes.array,
    }),
    siblings: PropTypes.shape({
      edges: PropTypes.array,
    }),
    weddingGiftingImage: PropTypes.shape({
      childImageSharp: PropTypes.object
    })
  }),
}

export default CategoryPage

export const CategoryPageQuery = graphql`
  query CategoryPage($id: String!, $title: String!, $parent: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoTitle
        intro
        parent
        metaDescription
        images {
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          caption
        }
      }
    }
    parentslug: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "category-page" }
          title: { eq: $parent }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
        }
      }
    }
    children: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "category-page" }
          parent: { eq: $title }
        }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            templateKey
            images {
              image {
                id
              }
            }
            cover_photo {
              childImageSharp {
                fluid(maxWidth: 768) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
          }
          fields {
            slug
          }
        }
      }
    }
    siblings: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "category-page" }
          parent: { eq: $parent, ne: null }
          title: { ne: $title }
        }
      }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            templateKey
            parent
          }
          fields {
            slug
          }
        }
      }
    }
    weddingGiftingImage: file(absolutePath: {regex:"/grid-wedding-gifting.jpg/"}) {
      childImageSharp {
        fluid(maxWidth: 768) {
         ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
