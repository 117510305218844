import React from "react"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload"
import { library } from "@fortawesome/fontawesome-svg-core"

library.add(faDownload)
class GridImage extends React.Component {
  render() {
    const data = this.props
    const image = data.image
    const alt = data.alt
    if (!image) {
      return "Can't Find Image"
    }
    return (
      <div class="position-relative">
        <Img
          fluid={image.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt={alt}
          className={"gatsby-grid-image"}
        />
        <a href={image.publicURL} download class="download-icon">
          <FontAwesomeIcon icon={["fas", "download"]} />
        </a>
      </div>
    )
  }
}

export default GridImage
