import React from "react"
import Img from "gatsby-image"

class CoverImage extends React.Component {
  render() {

    const data = this.props
    if(data.image) {
    const image = data.image.childImageSharp.fluid
    const alt = data.alt
    if (!image) {
      return "Can't Find Image"
    }
    return (
      <Img
        fluid={image}
        alt={alt}
        className={"w-100"}
      />
    )
    } else {
      return "Can't find image"
    }
  }
}

export default CoverImage
